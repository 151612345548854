import request from '../utils/request'
import qs from 'qs'
//用户注册接口-个人
export function register(data) {
    return request({
        method: 'POST',
        url: 'user/register',
        data
    })
}
//用户注册接口-企业
export function entRegister(data) {
    return request({
        method: 'POST',
        url: 'user/enterprise/register',
        data
    })
}
//发送短信验证码
export function sendSMS(data) {
    return request({
        method: 'POST',
        url: 'sms/sendCode',
        data
        // data: qs.stringify(data)
    })
}
//账号密码登陆接口
export function login(data) {
    return request({
        method: 'POST',
        url: 'user/login',
        data
        // data: qs.stringify(data)
    })
}

//账号退出
export function logout(data) {
    return request({
        method: 'POST',
        url: 'user/logout',
        data: qs.stringify(data)
    })
}

//用户手机号重复检查
export function phoneCheck(data) {
    return request({
        method: 'POST',
        url: 'user/phone/check',
        data: qs.stringify(data)
    })
}
//用户userName重复性检查
export function userNameCheck(data) {
    return request({
        method: 'POST',
        url: 'user/phone/check',
        data
    })
}

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
  return reg.test(email)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/* 验证密码 */
export function isPassword(pwd) {
  // return !(/(?!^(\d+|[a-z]+|[A-Z]+|[~!@#$%^&\*\(\)_\+|\{\}:\"\<\>\?/\.,\\';\]\[\=\`\-]+)$)^[\w~!@#$%^&\*\(\)_\+|\{\}:\"\<\>\?/\.,\\';\]\[\=\`\-]{6,20}$/.test(pwd))
  // 密码长度6~20位，须英文字母开头且包含数字、字母、符号$@_#!至少2种或以上元素
  return !(/^(?![a-z]+$)(?![A-Z]+$)(?!\d+$)(?![$@%_]+$)[a-zA-Z][\w$@%]{6,20}$/.test(pwd))
}
/* 验证手机 */
export function isPhone(phone) {
  return !(/^1(3|4|5|6|7|8|9)\d{9}$/.test(phone))
}
/* 验证银行卡号 */
export function isCard(phone) {
  return !(/^\d+$/.test(phone))
}

/* 验证身份证15 */
export function isId15(id) {
  return !(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(id))
}

/* 验证身份证18 */
export function isId18(id) {
  return !(/^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(id))
}

/* 验证数字 */
export function isNumber(num) {
  return !(/^\d+(?=\.{0,1}\d+$|$)/.test(num))
}

/* 验证最多两位小数 */
export function isshu(num) {
  return /^(\d+|\d+\.\d{1,2})$/.test(num)
}
/* 验证固定电话 */
export function isTel(tel) {
  return /^(\(\d{3,4}\)|\d{3,4}-)?\d{7,8}$/.test(tel)
}

/**
 * @param {object} object
 * @returns {Boolean}
 */
export function objectValueAllEmpty(object) {
  var isEmpty = true
  Object.keys(object).forEach(function(x) {
    if (object[x] !== null && object[x] !== '') {
      isEmpty = false
    }
  })
  if (isEmpty) { // 值全为空
    return true
  }
  return false
}
/**
 * 生成唯一UUID
 */
export function getUuid() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url
  const search = url.substring(url.lastIndexOf('?') + 1)
  const obj = {}
  const reg = /([^?&=]+)=([^?&=]*)/g
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1)
    let val = decodeURIComponent($2)
    val = String(val)
    obj[name] = val
    return rs
  })
  return obj
}

const getters = {
  
  token: state => state.user.token, // token

  phone: state => state.user.phone,

  userName: state => state.user.userName,

  nickName: state => state.user.nickName,

  userId: state => state.user.userId,

  role: state => state.user.role,

  userInfo: state => state.user.userInfo,


}
export default getters
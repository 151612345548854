<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <classifyAndNav />
    <banner/> -->
    <router-view></router-view>
    <!-- <login></login> -->
    <!-- <IndividualRegistration></IndividualRegistration> -->
  </div>
</template>

<!-- <script type="text/javascript" src=https://api.map.baidu.com/api?v=2.0&ak=EORBGLTvncfjMQfLb0iqUa8AINOVVDEu">
</script> -->
<script>
// import HelloWorld from './components/HelloWorld.vue'
// import Banner from './components/Banner.vue'
// import ClassifyAndNav from './components/ClassifyAndNav.vue'
// import Login from './views/login/Login.vue'
// import IndividualRegistration from './views/register/IndividualRegistration.vue'
export default {
  name: 'App',
  components: {
    // HelloWorld,
    // Banner,
    // ClassifyAndNav
    // Login,
    // IndividualRegistration
  }
}
</script>

<style>
#app {
  font-family:PingFang SC,Helvetica Neue,Helvetica,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 16px;
  min-width: 1170px;
}
</style>

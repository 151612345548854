import { login, logout, register, entRegister } from '@/api/login'
import { getToken, removeToken, setToken } from '@/utils/auth'
import { Message } from 'element-ui'

function companyType(name) {
  if (name === 'token') {
    if (getToken()) {
      try {
        return JSON.parse(getToken()).token
      } catch (e) {
        return
      }
    } else {
      return
    }
  } else if (name === 'nickName') {
    if (getToken()) {
      try {
        return JSON.parse(getToken()).userInfo.nickName
      } catch (e) {
        return
      }
    } else {
      return
    }
  } else if (name === 'phone') {
    if (getToken()) {
      try {
        return JSON.parse(getToken()).userInfo.phone
      } catch (e) {
        return
      }
    } else {
      return
    }
  } else if (name === 'userName') {
    if (getToken()) {
      try {
        return JSON.parse(getToken()).userInfo.userName
      } catch (e) {
        return
      }
    } else {
      return
    }
  } else if (name === 'userId') {
    if (getToken()) {
      try {
        return JSON.parse(getToken()).userInfo.userId
      } catch (e) {
        return
      }
    } else {
      return
    }
  } else if (name === 'role') {
    if (getToken()) {
      try {
        return JSON.parse(getToken()).userInfo.role
      } catch (e) {
        return
      }
    } else {
      return
    }
  } else if (name === 'userInfo') {
    if (getToken()) {
      try {
        return JSON.parse(getToken()).userInfo
      } catch (e) {
        return
      }
    } else {
      return
    }
  }

}


const state = {
  token: companyType('token'),
  nickName: companyType('nickName'),
  userName: companyType('userName'),
  userId: companyType('userId'),
  phone: companyType('phone'),
  role: companyType('role'),
  userInfo: companyType('userInfo')
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USERNAME: (state, username) => {
    state.username = username
  },
  SET_PHONE: (state, phone) => {
    state.phone = phone
  },
  SET_USERID: (state, userId) => {
    state.userId = userId
  },
  SET_ROLE: (state, role) => {
    state.role = role
  },
}


const actions = {
  // 用户注册-个人
  register({ commit }, userInfo) {
    console.log(userInfo);
    return new Promise((resolve, reject) => {

      register(userInfo).then(res => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {


            const { data } = res.data
            setToken(data)
            commit('SET_TOKEN', data.token)
            console.log(data.token);
            console.log(data.userInfo.phone);
            commit('SET_PHONE', data.userInfo.phone)
            commit('SET_USERNAME', data.userInfo.nickName)
            Message({
              type: 'success',
              message: res.data.message,
              duration: 1000,
              onClose: () => {
                resolve(res.data)
              }
            })
            // resolve()
          } else {
            // if( res.data.code === 24) {
            console.log(res.data);
            Message({
              type: 'error',
              message: res.data.message,
              duration: 1000,
              onClose: () => {
                reject()
              }
            })
            reject(res.data)
            // }
          }

        }


      }).catch(err => {
        console.log(err);
        reject(err)

      })
    })
  },

  //注册-企业
  entRegister({ commit }, userInfo) {
    console.log(userInfo);
    return new Promise((resolve, reject) => {
      entRegister(userInfo).then(res => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.code === 0) {


            const { data } = res.data
            setToken(data)
            commit('SET_TOKEN', data.token)
            commit('SET_PHONE', data.userInfo.phone)
            commit('SET_USERNAME', data.userInfo.nickName)
            Message({
              type: 'success',
              message: res.data.message,
              duration: 1000,
              onClose: () => {
                resolve(res.data)
              }
            })
            // resolve()
          } else {
            // if( res.data.code === 24) {
            console.log(res.code);
            console.log('111', res);
            Message({
              type: 'error',
              message: res.data.message,
              duration: 1000,
              onClose: () => {
                resolve(res)
              }
            })
            // }
          }

        }


      }).catch(err => {
        console.log(err);
        reject(err)

      })
    })
  },

  // 用户登录
  login({ commit }, userInfo) {
    console.log('login');
    return new Promise((resolve, reject) => {
      login(userInfo).then(res => {
        console.log(res);
        if (res.status === 200) {
          const { data } = res.data
          if (res.data.code === 0) {
            setToken(data)
            commit('SET_TOKEN', data.token)
            resolve(res.data)
          } else {
            Message({
              type: 'error',
              message: res.data.message
            })
          }

        }

      }).catch(err => {
        reject(err)
      })
    })
  },

  //退出
  logout({ commit, state, dispatch }) {
    console.log('logout...');
    return new Promise((resolve, reject) => {
      logout(state.token).then((res) => {

        commit('SET_TOKEN', '')
        removeToken()

        // 重置访问的视图和缓存的视图
        dispatch('tagsView/delAllViews', null)

        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },

  //移除token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
      this.$router.push({
        path: '/login'
      })
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
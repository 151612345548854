import {
    finishPeriod
} from '@/api/course'

const actions = {
    // 课时结束
    finishPeriod(params) {
        return new Promise((resolve, reject) => {
            console.log("actions finishPeriod" + params)
            finishPeriod(params).then(res => {
                console.log(res);
            }).catch(err => {
                reject(err)
            })
        })
    },
}

export default {
    namespaced: true,
    actions
}
// 课时相关api
import request from '../utils/request'
// import qs from 'qs'

//0010062-获取课时视频播放信息
export function getVodArguments(params={}) {
  return request({
      method: 'GET',
      url:'course/vodArguments',
      params
  })
}

export function startPeriod(data) {
  return request({
      method: 'POST',
      url: 'course/start',
      data
  })
}

export function finishPeriod(data) {
    return request({
        method: 'POST',
        url: 'course/finishPeriod',
        data
    })
}

export function finishPeriodNew(data) {
    return request({
        method: 'POST',
        url: 'course/finishPeriodNew',
        keepalive: true,
        data
    })
}

//接口调用失败后调用
export function recordError(data) {
    return request({
        method: 'POST',
        url: 'course/recordFinishError',
        data
    })
}

//倒计时完成后调用
export function recordEnding(data) {
    return request({
        method: 'POST',
        url: 'course/recordFinishEnding',
        data
    })
}

// 0010115-课程前端统计
export function getFrontStat(params = {}) {
  return request({
      method: 'GET',
      url: 'course/front/stat',
      params
  })
}
// 0010116-课程最近学员
export function getSignupRecent(params = {}) {
  return request({
      method: 'GET',
      url: 'course/signup/recent',
      params
  })
}
// 0010117-学员动态
export function getUserTrends(params = {}) {
  return request({
      method: 'GET',
      url: 'course/user/trends',
      params
  })
}

export function faceCompare(data) {
  return request({
      method: 'POST',
      url: 'realname/compareFace',
      data
  })
}

export function getCourseMonitorSetting(data) {
  return request({
      method: 'GET',
      url: 'course/monitor/setting',
      params : data
  })
}

export function uploadEndowments(data) {
    return request({
        method: 'POST',
        url: 'course/uploadEndowments',
        data
    })
}

export function endowmentsData(data) {
    return request({
        method: 'GET',
        url: 'course/endowmentsData',
        params: data
    })
}
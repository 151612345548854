import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
Vue.use(Router)


export const constantRoutes = [
  {
    path: '/',
    name: '乌鲁木齐建设职业培训中心',
    component: () => import('@/views/index/index'),
    // 任何人都可以阅读文章
    meta: { requiresAuth: true } //有这个属性就不要求登录都可以访问
  },
  {
    path: '/login',
    name: '个人/企业登录',
    component: () => import('@/views/login/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/register',
    name: '个人/企业注册',
    component: () => import('@/views/register/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/classroom',
    name: '课程列表',
    component: () => import('@/views/classroom/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/classdetail',
    name: '课程详情',
    component: () => import('@/views/detail/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/classlive',
    name: '课程直播',
    component: () => import('@/views/detail/live'),
    meta: { requiresAuth: true }
  },
  {
    path: '/courseDetail',  //暂时废弃
    name: 'courseDetail',
    component: () => import('@/views/course/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/classRegion',  //地州列表/ 精品列表 / 最新课程
    name: '地州课程',
    component: () => import('@/views/classRegion/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/highQuality',  //地州列表/ 精品列表 / 最新课程
    name: '精品课程',
    component: () => import('@/views/highQuality/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/newlyCourse',  //地州列表/ 精品列表 / 最新课程
    name: '最新课程',
    component: () => import('@/views/newlyCourse/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/openCourse', // 公开课
    name: '公开课',
    component: () => import('@/views/openCourse/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/liveCourse', // 公开课
    name: '直播课',
    component: () => import('@/views/liveCourse/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/myCenter',
    name: '个人中心',
    redirect: '/myCenter/course', // 默认选中此页面
    component: () => import('@/views/myCenter/index'),
    meta: {
      roles: [1] // 1 个人用户； 2 企业用户
    },
    children: [
      {
        path: 'basicinfo',
        name: '基本信息',
        component: () => import('@/views/myCenter/components/Basicinfo'),
        meta: {
          // roles: [1]
          requiresAuth: true
        },
      },
      {
        path: 'approval',
        name: '实名认证',
        component: () => import('@/views/myCenter/components/Approval'),
        meta: {
          roles: [1]
          // requiresAuth: true
        },
      },
      /* {
        path: 'course',
        name: 'course',
        component: ()=> import('@/views/myCenter/components/course')
      }, */
      /*{
        path: 'study',
        name: 'study',
        component: ()=> import('@/views/myCenter/components/study'),
        children: [*/
      { // 课程
        path: 'course',
        name: '我的课程',
        component: () => import('@/views/myCenter/components/Course'),
        meta: {
          roles: [1]
        },
      },
      /* { // 笔记 - 2023-2-20  不要
        path: 'notebooks',
        name: 'notebooks',
        component: () => import('@/views/myCenter/components/Notebooks'),
        meta: {
          roles: [1]
        },
      }, */
      { //  教材
        path: 'textbook',
        name: '我的教材',
        component: () => import('@/views/myCenter/components/Textbook'),
        meta: {
          roles: [1]
        },
      },
      { //  作业
        path: 'homework',
        name: '我的作业',
        component: () => import('@/views/myCenter/components/Homework'),
        meta: {
          roles: [1]
        },
      },
      { //  换课申请
        path: 'changeCourses',
        name: '换课申请',
        component: () => import('@/views/myCenter/components/ChangeCourses'),
        meta: {
          roles: [1]
        },
      },
      // ]
      // },
      { //我的订单
        path: 'orderlist',
        name: '我的订单',
        component: () => import('@/views/myCenter/components/Orderlist'),
        meta: {
          roles: [1]
        },
      },
      { //我的发票
        path: 'invoice',
        name: '我的发票',
        component: () => import('@/views/myCenter/components/Invoice'),
        meta: {
          roles: [1]
        },
      },
      { //账号解绑
        path: 'unbinding',
        name: '账号解绑',
        component: () => import('@/views/myCenter/components/Unbinding'),
        meta: {
          roles: [1]
        },
      },
      { //我的简历
        path: 'resume',
        name: '我的简历',
        component: () => import('@/views/myCenter/components/Resume'),
        meta: {
          roles: [1]
        },
      },

      // { //我的积分 2023-2-20 
      //   path: 'integral',
      //   name: 'integral',
      //   component: () => import('@/views/myCenter/components/Integral'),
      //   meta: {
      //     roles: [1]
      //   },
      // },
      { // 升级会员
        path: 'member',
        name: '升级会员',
        component: () => import('@/views/myCenter/components/Member'),
        meta: {
          roles: [1]
        },
      },


      { // 考试
        path: 'testpaper',
        name: '我的考试',
        component: () => import('@/views/myCenter/components/Testpaper'),
        meta: {
          roles: [1]
        },
      },
      { //修改密码
        path: 'password',
        name: '修改密码',
        component: () => import('@/views/myCenter/components/Password'),
        meta: {
          roles: [1]
        },
      },
      { //消息通知
        path: 'message',
        name: '消息通知',
        component: () => import('@/views/myCenter/components/Message'),
        meta: {
          roles: [1]
        },
      },
      { //账号注销
        path: 'cancellation',
        name: '账号注销',
        component: () => import('@/views/myCenter/components/Cancellation'),
        meta: {
          roles: [1]
        },
      },
      { //证书
        path: 'certificate',
        name: '我的证书',
        component: () => import('@/views/myCenter/components/Certificate'),
        meta: {
          roles: [1]
        },
      },
      { //证书
        path: 'addCertificate',
        name: '添加证书',
        component: () => import('@/views/myCenter/components/AddCertificate'),
        meta: {
          roles: [1]
        },
      },
      { //通知详情
        path: 'noticeDetail',
        name: '通知详情',
        component: () => import('@/views/myCenter/components/NoticeDetail'),
        meta: {
          roles: [1]
        },
      },
    ]
  },
  { //企业中心
    path: '/entCenter',
    name: '企业中心',
    // redirect: '/entCenter/entBasicinfo', // 默认选中此页面
    redirect: '/entCenter/classManagement', // 默认选中此页面
    component: () => import('@/views/entCenter/index'),
    meta: {
      roles: [2] // 1 个人用户； 2 企业用户
    },
    children: [
      {
        path: 'entBasicinfo',
        name: '企业基本信息',
        component: () => import('@/views/entCenter/components/Basicinfo'),
        meta: {
          roles: [2]
        },
      },
      { // 课程
        path: 'course',
        name: '企业课程',
        component: () => import('@/views/entCenter/components/Course'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'memberManagement',
        name: '成员管理',
        component: () => import('@/views/entCenter/components/MemberManagement'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'entMemberClass',
        name: '成员课程',
        component: () => import('@/views/entCenter/components/EntMemberClass'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'invoiceManagement',
        name: '发票管理',
        component: () => import('@/views/entCenter/components/InvoiceManagement'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'orderlistManagement',
        name: '企业订单',
        component: () => import('@/views/entCenter/components/OrderlistManagement'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'studySearch',
        name: '学习查询',
        component: () => import('@/views/entCenter/components/StudySearch'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'orderDetail',
        name: '订单详情',
        component: () => import('@/views/entCenter/components/OrderDetail'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'entUnbinding',
        name: '手机号解绑',
        component: () => import('@/views/entCenter/components/EntUnbinding'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'entPassword',
        name: '修改密码',
        component: () => import('@/views/entCenter/components/Password'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'entMember',
        name: '企业成员',
        component: () => import('@/views/entCenter/components/Member'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'classManagement',
        name: '课程管理',
        component: () => import('@/views/entCenter/components/ClassManagement'),
        meta: {
          roles: [2]
        },
      },
      {
        path: 'entCancellation',
        name: '企业注销',
        component: () => import('@/views/entCenter/components/Cancellation'),
        meta: {
          roles: [2]
        },
      },
    ]
  },
  // {
  //   path: '*',
  //   redirect: '/'
  // },
  {
    path: '/task',
    name: '任务学习',
    component: () => import('@/views/task/index'),
    //meta: { requiresAuth: true }
    meta: {
      roles: [1, 2]
    }
  },
  {
    path: '/taskResult',
    name: '考试结果',
    component: () => import('@/views/task/taskResult'),
    //meta: { requiresAuth: true }
    meta: {
      roles: [1, 2]
    }
  },
  {
    path: '/video',
    name: '视频学习',
    component: () => import('@/views/video/index'),
    //meta: { requiresAuth: true }
    meta: {
      requiresAuth: true,
      // roles: [1,2]
    }
  },
  {
    path: '/aboutus',
    name: '关于我们',
    component: () => import('@/views/aboutus/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/super',
    name: '监控',
    component: () => import('@/views/aboutus/super'),
    meta: { requiresAuth: true }
  },
  {
    path: '/noticeList',
    name: '公告',
    component: () => import('@/views/noticeList/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/news',
    name: '资讯频道',
    component: () => import('@/views/news/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/article',
    name: '资讯详情',
    component: () => import('@/views/article/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/password/reset',
    name: '重设密码',
    component: () => import('@/views/findPassword/index'),
    meta: { requiresAuth: true }
  },
  {
    path: '/confirm/order',
    name: '订单确认',
    component: () => import('@/views/confirmOrder/index'),
    meta: {
      // roles: [1,2]
      requiresAuth: true
    }
  },
  {
    path: '/confirm/payment',
    name: '订单支付',
    component: () => import('@/views/payment/index'),
    meta: {
      roles: [1, 2]
    }
  }
]


const createRouter = () => new Router({
  mode: 'history', // hash, history 两种模式
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

// 判断是否登录状态
router.beforeEach((to, from, next) => {
  console.log('to:', to);
  console.log('from:', from);
  const token = store.getters.token
  // console.log(store.getters.token, token);
  const role = store.getters.role
  // console.log('role:', role);
  // debugger
  if (to.matched.some(record => record.meta.requiresAuth || record.meta.homePages)) {
    //路由元信息requireAuth:true，或者homePages:true，则不做登录校验
    console.log('requireAuth:true');
    next()
  } else {
    if (token) {//判断用户是否登录
      if (to.meta.roles.includes(role)) {
        next() // 放行
      } else {
        next({
          path: '/' // 首页
        })
      }

      // console.log(Object.keys(from.query).length)
      /* if(Object.keys(from.query).length === 0){//判断路由来源是否有query，处理不是目的跳转的情况
          next()
      }else{
          let redirect = from.query.redirect//如果来源路由有query
          console.log(redirect);
          if(to.path === redirect){//这行是解决next无限循环的问题
              next()
          }else{
              next({path:redirect})//跳转到目的路由
          }
      } */

    } else {
      if (to.path === "/login") {
        next()
      } else {
        next({
          path: "/login",
          query: { redirect: to.fullPath }//将目的路由地址存入login的query中
        })
      }
    }
  }
  return

  /*console.log('to:',to, 'from:', from, 'next:', next);
  if(to.path === '/login') return next()
  let tokenStr = window.sessionStorage.getItem('token')
  tokenStr = 'sss'
  let reg = /^\/myCenter/  // 匹配以/myCenter开头的URL
  if(reg.test(to.path) && tokenStr === null) {
    // alert('请先登录后访问个人中心页面！')
    next('/login')
  }
  console.log(tokenStr);
  // if(tokenStr) return next('/login')
  next()*/
})

export default router
import Vue from 'vue'
import App from './App.vue'
import Cookies from 'js-cookie'
import './common/css/base.scss' // a modern alternative to CSS resets

import Element from 'element-ui'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import 'quill/dist/quill.snow.css';

import 'element-ui/lib/theme-chalk/index.css';

import router from './router'
import store from './store'
// import api from './api'
// Vue.prototype.$api = api

import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {

  ak: 'EORBGLTvncfjMQfLb0iqUa8AINOVVDEu'

})

import './assets/iconfont/iconfont.css'

router.beforeEach((to, from ,next)=>{
  document.title = to.name // 让页面显示路由对应的name值
  console.log(from);
  next()
})



Vue.use(VueAwesomeSwiper)
Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

import axios from 'axios'
import { getUuid } from '@/utils/validate'

import store from '@/store'
import { getToken } from '@/utils/auth'
// MessageBox import { log } from 'console'
import { Message } from 'element-ui'
const service = axios.create({
  // baseURL: 'http://localhost:10001/api/', //开发环境
  // baseURL: 'https://test-html.www.wlmqcol.com/api/', //测试环境
  baseURL: 'https://www.wlmqcol.com/api/', ///线上环境
  // baseURL: '/api/',
  // timeout: 5000,
  timeout: 60000,
  headers: {
    traceId: getUuid(),
    ts: new Date().valueOf()
  }
})

service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      console.log(getToken());
      let val = JSON.parse(getToken())
      console.log(val);
      // const { token } = JSON.parse(getToken())
      config.headers['x-u-token'] = val.token
      // config.headers['authentication'] = 'USERID ' + base64.encode(`${admin.mobile}:${access_token}:${admin.id}`)
    }
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use((res) => {
  console.log('37:', res);
  const { code, message } = res.data
  console.log(message, code);
  console.log(res.data);
  if (code === 0) {
    return res
  } else if (code === 22) { //未登录
    /* Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    }) */
    store.dispatch('user/resetToken').then(() => {
      // location.reload() // 为了重新实例化vue-router对象 
    })


    // console.log(message);
    /* Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(message) */
    /* MessageBox.confirm(
      '使用此功能需要您先进行登录！', {
        confirmButtonText: '确定',
        showConfirmButton: false,
        cancelButtonText: '取消',
        showCancelButton: false,
        type: 'warning',
        onClose:()=>{ // 清除token
          console.log('onClose...');
          store.dispatch('user/resetToken').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }
      }
    ).then(() => { */
    /* console.log('then...', this);
    this.$router.push({
      path: "login",
    }); */
    /* console.log('then...')
    store.dispatch('user/resetToken').then(() => {
      location.reload() // 为了重新实例化vue-router对象 避免bug
    })

  }) */
    return Promise.resolve(res)
    // return res.data
  } else {
    return Promise.resolve(res)
  }
},
  error => {
    console.log('err:' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 1.5 * 1000
    })
    return Promise.reject(error)
  })


export default service